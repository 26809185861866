const wechatInfoReplay = {
  path: 'wechatInfoReplay/:complaint_id',
  component: resolve => require(['@/pages/admin/aftersales/wechatInfoReplay'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
  ]
}

export default wechatInfoReplay