const wechatreplay = {
  path: 'wechatreplay/:complaint_id/:complainted_mchid',
  component: resolve => require(['@/pages/admin/aftersales/wechatreplay'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default wechatreplay