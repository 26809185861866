import agentMessageList from './list'
import agentMessageDetail from './detail'

const agentMessage = {
  path: '/agentMessage',
  component: resolve => require(['@/pages/admin/agentMessage'], resolve),
  redirect: '/agentMessage/list',
  children: [
    agentMessageList,
    agentMessageDetail,
  ],
  meta: {
    requireAuth: false
  }
}

export default agentMessage
