import merchantList from './list'
import merchantAdd from './add'
import merchantSigning from './signing'
import merchantDispose from './dispose'
import merchantEdit from './edit'

const merchant = {
  path: '/merchant',
  component: resolve => require(['@/pages/admin/merchant'], resolve),
  redirect: '/merchant/list',
  children: [
    merchantList,
    merchantAdd,
    merchantSigning,
    merchantDispose,
    merchantEdit,
  ],
  meta: {
    requireAuth: true
  }
}

export default merchant
