import alipayAccessPay from './accessPay'
import alipayFound from './found'
import alipayLaunch from './launch'
import alipayRate from './rate'
import alipaySubLedger from './subLedger'
import merchantTest from './merchantTest'

const alipay = {
  path: 'alipay/:merchantId',
  component: resolve => require(['@/pages/admin/reviewed/alipay'], resolve),
  redirect: 'alipay/:merchantId/accessPay',
  children: [
    alipayFound,
    alipayAccessPay,
    alipayLaunch,
    alipaySubLedger,
    alipayRate,
    merchantTest
  ]
}

export default alipay
