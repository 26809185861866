const managebanner = {
  path: 'agentbanner/managebanner',
  component: resolve => require(['@/pages/admin/platform/agentbanner/managebanner'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default managebanner