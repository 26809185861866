const alipayreplay = {
  path: 'alipayreplay/:complain_event_id',
  component: resolve => require(['@/pages/admin/aftersales/alipayreplay'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default alipayreplay