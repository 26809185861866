
const merchantBlack = {
    path: 'merchant_black',
    component: resolve => require(['@/pages/admin/aftersales/merchantBlack/index.vue'], resolve),
    meta: {
        requireAuth: true
    },
    children: [

    ]
}

export default merchantBlack