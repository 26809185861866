import messageCenterList from './list'

const messageCenter = {
    path: '/messageCenter',
    component: resolve => require(['@/pages/admin/messageCenter'], resolve),
    redirect: '/messageCenter/list',
    children: [
        messageCenterList,
    ],
    meta: {
        requireAuth: false
    }
}

export default messageCenter
