// import aftersalesReplayList from './list'
const addfile = {
  path: 'contract/addfile',
  component: resolve => require(['@/pages/admin/platform/contract/addfile'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addfile
