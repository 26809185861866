import expense from './expense'
import orderList from './order'
import record from './record'

const recharge = {
  path: 'recharge/:Id',
  component: resolve => require(['@/pages/admin/recharge'], resolve),
  redirect: 'recharge/:Id/record',
  children: [
    expense,
    orderList,
    record
  ],
  meta: {
    requireAuth: true
  },
}

export default recharge