// import aftersalesReplayList from './list'
const addlabel = {
  path: 'labelList/addlabel',
  component: resolve => require(['@/pages/admin/platform/message/labelList/addlabel'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addlabel