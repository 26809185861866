<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import "styles/index";
  #app {
    height: 100%;
  }
</style>
