// import aftersalesReplayList from './list'
const messageInfo = {
  path: 'messageInfo',
  component: resolve => require(['@/pages/admin/platform/message/messageInfo'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default messageInfo
