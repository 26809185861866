import base from "./base"
import password from "./password"
const trading = {
    path: '/user_info',
    component: resolve => require(['@/pages/admin/user_info'], resolve),
    redirect: '/user_info/base',
    children: [
        base,
        password
    ],
    meta: {
        requireAuth: true
    },
}

export default trading