import wechatFound from './found'
import wechatAccessPay from './accessPay'
import wechatLaunch from './launch'
import wechatSubLedger from './subLedger'
import wechatRate from './rate'
import successPay from './accessPay/success'
import merchantTest from './merchantTest'

const wechat = {
  path: 'wechat/:merchantId',
  component: resolve => require(['@/pages/admin/reviewed/wechat'], resolve),
  redirect: 'wechat/:merchantId/found',
  children: [
    wechatFound,
    wechatAccessPay,
    wechatLaunch,
    wechatSubLedger,
    wechatRate,
    successPay,
    merchantTest
  ]
}

export default wechat
