import shopkeeperList from './list'
import shopkeeperDowns from './downs'
import shopkeeperDownsNew from './downsNew'
import shopkeeperAdd from './add'
import shopkeeperEdit from './edit'
import shopkeeperStoreList from './storeList'


const shopkeeper = {
  path: 'shopkeeper',
  component: resolve => require(['../../../../../../pages/admin/merchant/dispose/shopkeeper'], resolve),
  redirect: 'shopkeeper/list',
  children: [
    shopkeeperList,
    shopkeeperDowns,
    shopkeeperAdd,
    shopkeeperEdit,
    shopkeeperStoreList,
    shopkeeperDownsNew
  ],
  meta: {
    requireAuth: true
  }
}

export default shopkeeper