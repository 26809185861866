const alipayInfo = {
  path: 'alipayInfo/:id',
  component: resolve => require(['@/pages/admin/aftersales/alipayInfo'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default alipayInfo