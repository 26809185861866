// import aftersalesReplayList from './list'
const addmessageClass = {
  path: 'messageClassList/addmessageClass',
  component: resolve => require(['@/pages/admin/platform/message/messageClassList/addmessageClass'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addmessageClass
