import marketingList from './marketingList'
import addmarketing from './marketingList/addmarketing'


const marketing = {
  path: '/marketing',
  component: resolve => require(['@/pages/admin/marketing'], resolve),
  redirect: '/marketing/marketingList',

  children: [
    marketingList,
    addmarketing, //添加
  ],
  meta: {
    requireAuth: true
  },
}

export default marketing
