// import aftersalesReplayList from './list'
const labelInfo = {
  path: 'labelList/labelInfo',
  component: resolve => require(['@/pages/admin/platform/message/labelList/labelInfo'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default labelInfo