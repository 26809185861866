const wechatInfo = {
  path: 'wechatInfo/:id',
  component: resolve => require(['@/pages/admin/aftersales/wechatInfo'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default wechatInfo