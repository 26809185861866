const addMessage = {
  path: 'agentMessage/addMessage',
  component: resolve => require(['@/pages/admin/platform/agentMessage/addMessage'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
  ]
}

export default addMessage