import advertising from './advertising'
import addbanner from './advertising/addbanner'
import agentbanner from './agentbanner'
import addagentbanner from './agentbanner/addagentbanner'
import managebanner from './agentbanner/managebanner'
import contract from './contract'
import addfile from './contract/addfile'
import cooperation from './cooperation'
import suggestion from './suggestion'
import systemSet from './systemSet'
import addcategory from './contract/addcategory'

import messageClassList from './messageClassList'
import addmessageClass from './messageClassList/addmessageClass'
import messageList from './messageList'
import addmessage from './messageList/addmessage'
import messageInfo from './messageInfo'
import labelList from './labelList'
import addlabel from './labelList/addlabel'
import labelInfo from './labelList/labelInfo'
import mesTemplet from './mesTemplet'
import addmesTemplet from './mesTemplet/addmesTemplet'
import mesManagement from './mesManagement'
import addmesManagement from './mesManagement/addmesManagement'
import payChannelInterface from "./payChannelInterface"
import kefu from "./kefu"

// 代理商消息
import agentMessage from './agentMessage'
import agentAddMessage from './agentMessage/addMessage'

const platform = {
  path: '/platform',
  component: resolve => require(['@/pages/admin/platform'], resolve),
  redirect: '/platform/advertising',

  children: [
    advertising,
    managebanner, //广告管理
    addbanner, //添加
    agentbanner,
    addagentbanner,
    contract,
    addfile,
    cooperation,
    suggestion,
    systemSet,
    addcategory,
    messageClassList,
    messageInfo, //详情
    addmessageClass, //添加
    messageList,
    addmessage,
    labelList,
    addlabel,
    labelInfo,
    mesTemplet,
    addmesTemplet,
    mesManagement,
    addmesManagement,
    payChannelInterface,
	agentMessage,
	agentAddMessage,
      kefu
  ],
  meta: {
    requireAuth: true
  },
}

export default platform
