// import aftersalesReplayList from './list'
const addmesTemplet = {
  path: 'mesTemplet/addmesTemplet',
  component: resolve => require(['@/pages/admin/platform/message/mesTemplet/addmesTemplet'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addmesTemplet