import accessPayCreate from './create'
import accessPayList from './list'
import accessPaySuccess from './success'

const accessPay = {
  path: 'accessPay',
  component: resolve => require(['@/pages/admin/merchant/signing/yftWeChat/accessPay'], resolve),
  redirect: 'accessPay/list',
  meta: {isAccessPay: true},
  children: [
    accessPayCreate,
    accessPayList,
    accessPaySuccess
  ],
}

export default accessPay