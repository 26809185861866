import alipayLaunch from './launch'
import alipayAccessPay from './accessPay'

const shopCodeAlipay = {
  path: 'shopCodeAlipay',
  component: resolve => require(['../../../../../../pages/admin/merchant/signing/shopCodeAlipay'], resolve),
  redirect: 'shopCodeAlipay/accessPay',
  children: [
    alipayLaunch,
    alipayAccessPay
  ],
}

export default shopCodeAlipay