// import aftersalesReplayList from './list'
const addmarketing = {
  path: 'marketingList/addmarketing',
  component: resolve => require(['@/pages/admin/marketing/marketingList/addmarketing'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addmarketing
