// import aftersalesReplayList from './list'
const addmesManagement = {
  path: 'mesManagement/addmesManagement',
  component: resolve => require(['@/pages/admin/platform/message/mesManagement/addmesManagement'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addmesManagement