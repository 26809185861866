
const merchantAppeals = {
    path: 'merchant_appeals',
    component: resolve => require(['@/pages/admin/aftersales/merchantAppeals/index.vue'], resolve),
    meta: {
        requireAuth: true
    },
    children: [

    ]
}

export default merchantAppeals