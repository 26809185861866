import Vue from 'vue'
import Vuex from 'vuex'
import stepsNums from './modules/steps'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    stepsNums
  }
})
export default store
