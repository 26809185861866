import wechatFound from './found'
import wechatAccessPay from './accessPay'
import wechatLaunch from './launch'
import wechatRate from './rate'
import wechatSubLedger from './subLedger'
import merchantTest from './merchantTest'

const wechat = {
  path: 'wechat',
  component: resolve => require(['@/pages/admin/merchant/signing/yftWeChat'], resolve),
  redirect: 'wechat/found',
  children: [
    wechatFound,
    wechatAccessPay,
    wechatLaunch,
    wechatRate,
    wechatSubLedger,
    merchantTest
  ],
}

export default wechat