  import Vue from 'vue'
  import axios from 'axios';
  import router from "../router/index";
	import ElementUI from "element-ui"
  import {
    debounce
  } from 'throttle-debounce'
  import {
    BASE_URL
  } from "@/config"

  import qs from 'qs'
  // 创建
  const $axios = axios.create({
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'text/html, */*',
      'X-Requested-With': 'XMLHttpRequest'
    },
    baseURL: BASE_URL
  })

  // 请求拦截器
  $axios.interceptors.request.use(config => {
    let token = localStorage.getItem('token')
    if (token) {
      let data = config.data
      config.headers["X-API-Key"] = token;
      const utype = localStorage.getItem('utype');
      // 如果是post请求就把默认参数拼到data里面
      // 如果是get请求就拼到params里面
      if (data instanceof FormData == false) {
          if (config.method === 'post') {
            if (data && !data.hasOwnProperty("utype")){
              config.data = {
                ...data,
                utype: utype
              }
            }else{
              config.data = {
                ...data
              }
            }
          } else if (config.method === 'get') {
            config.params = {
              ...data,
              utype: utype
            }
          }else{
            config.params = {
              ...data,
              utype: utype
            }
          }
        }
    }
    return config;
  })

  /* 会话失效弹窗提示 */
  const timeoutMessageBox = debounce(500, () => {
    ElementUI.MessageBox.alert('您的登录已经超时，请点击按钮重新登录', '提示', {
      center: true,
      confirmButtonText: '重新登录',
      callback: () => {
        router.push('/login')
      }
    })
  })

  /* 会话失效弹窗提示 */
  const loginOutMessageBox = debounce(500, () => {
    ElementUI.MessageBox.alert('您的账户已在他处登录，请检查并确认账户是否安全', '提示', {
      center: true,
      confirmButtonText: '重新登录',
      callback: () => {
        router.push('/login')
      }
    })
  })

  /* 会话失效弹窗提示 */
  const permissionDenyMessageBox = debounce(500, () => {
    ElementUI.MessageBox.alert('您没有访问当前页面的权限', '提示', {
      center: true,
      confirmButtonText: '确定'
    })
  })

  // 添加响应拦截器 code码后期和后端约定
  $axios.interceptors.response.use(response => {
		Vue.nextTick(()=>{
			if(response.data && response.data.code){
				switch(response.data.code){
					case 401: {
						timeoutMessageBox()
						break
					}
					case 403: {
					  permissionDenyMessageBox()
					  break
					}
                    case 406: {
                      loginOutMessageBox()
                      break
                    }
					default:{
						break
					}
				}
			}
		})
		
    return response
  }, error => {
    Vue.nextTick(() => {
      if (error.response && error.response.config) {
        switch (error.response.code) {
          case 401: {
            timeoutMessageBox()
            break
          }
          case 403: {
            permissionDenyMessageBox()
            break
          }
          case 406: {
            loginOutMessageBox()
            break
          }
          case 404: {
            break
          }
          case 500: {
            break
          }
          default: {
            break
          }
        }
      }
    })
    return Promise.reject(error)
  })

  /* 引入ajax请求方案 */
  export function ajax(config) {
    config.instance = this
    return $axios(config)
  }