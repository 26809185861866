import contractList from "./list";

const admin = {
    path: '/contract',
    component: resolve => require(['@/pages/admin/contract/index.vue'], resolve),
    redirect: '/contract',
    children: [
        contractList
    ],
    meta: {
        requireAuth: true
    }
}

export default admin