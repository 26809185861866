// import aftersalesReplayList from './list'
const replay = {
  path: 'replay/:orderId',
  component: resolve => require(['@/pages/admin/aftersales/replay'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default replay
