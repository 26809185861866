import accessPayCreate from './create'
import accessPayList from './list'
const accessPay = {
  path: 'accessPay',
  component: resolve => require(['../../../../../../../pages/admin/merchant/signing/yftAlipay/accessPay'], resolve),
  redirect: 'accessPay/list',
  meta: {isAccessPay: true},
  children: [
    accessPayCreate,
    accessPayList 
  ]
}

export default accessPay
